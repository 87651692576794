/*******************************
Lists
*******************************/

.list {
  margin: 10px 0;

  > ul {
    list-style: none;
    padding: 0;
    margin: 0;

    > li.list-item {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      padding: 0 10px;
      height: 45px;

      &:not(:last-child) {
        border-bottom: 1px solid $color-border;
      }

      &.disabled {
        align-items: center;
      }

      &.small {
        height: 36px;
        padding: 0 10px;

        .list-item-actions {
          > i {
            font-size: 16px;
          }
        }
      }

      &.solid {
        background-color: $color-off-white;
        border-radius: 8px;
        border-color: transparent;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }

      .loader {
        margin: 0 10px 0 0;
      }

      .meta:first-child {
        margin-right: 10px;
      }

      .checkbox-wrapper {
        margin: 0 12px 0 0;
      }

      .list-item-icon {
        width: 16px;
        height: 16px;
        margin: 0 10px 0 0;
      }

      .list-item-name {
        font-size: 14px;
        flex: 1;
      }

      .list-item-actions {
        display: none;
        align-items: center;

        > i {
          color: $color-text-light;
          cursor: pointer;
          font-size: 18px;
          margin-left: 12px;

          &:hover {
            color: $color-text;
          }
        }
      }

      .list-item-table {
        display: flex;
        margin-left: auto;

        > * {
          flex: 1;
          margin: 0 15px;
        }
      }

      &.clickable {
        &:hover {
          background-color: $color-off-white;
  
          .list-item-actions {
            display: flex;
          }
        }
  
        .list-item-name > span:hover {
          color: $color-link;
          cursor: pointer;
        }
      }

      &.failed {
        color: $color-danger;

        &:hover {
          background-color: $color-off-white;
          
          .list-item-actions {
            display: flex;
          }
        }
      }

      &.email-header {
        background: #f9f9f9;
        font-weight: bold;
        padding: 0.75em 0.5em;
        border-top: 1px solid #ddd;
      }
      
      &.attachment-subitem {
        padding-left: 2em;
        border-bottom: 2px solid #eee;
        background: #fff;
      }
      
    }
  }

  &.dark {
    > ul {
      > li.list-item {
        border-bottom-color: $color-border-dark;

        &:hover {
          background-color: $color-dark-blue;
        }
      }
    }
  }
}
